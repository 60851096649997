[
  {
    "id": "1",
    "title": "Minimal Unihockey Event",
    "description": null,
    "dateTime": "2023-03-30T14:00:00+0100",
    "address": "Bahnhofstrase 5, 8108 Irgendwo",
    "location": {
      "lat": "47.382439051313796",
      "lng": "8.50879145312597"
    },
    "organizer": {
      "name": "Stephan Hauser",
      "email": "stephan@email.ch",
      "phone": null
    },
    "participants": [],
    "attendants": []
  },
  {
    "id": "2",
    "title": "Unihockey C Nord in Zürich",
    "description": "Vorrunde",
    "dateTime": "2023-03-15T14:15:00+0100",
    "address": "Turnhalle Sportau, Schulstrasse 5, 3000 Bern",
    "location": {
      "lat": "47.382439051313796",
      "lng": "8.50879145312597"
    },
    "organizer": {
      "name": "Stephan Hauser",
      "email": "stephan@email.com",
      "phone": "+41791234567"
    },
    "participants": [
      {
        "id": "1",
        "state": "CONFIRMED",
        "contact": {
          "name": "Mia Knecht",
          "email": "mia.knecht@participant.ch",
          "phone": null
        },
        "origin": null
      },
      {
        "id": "2",
        "state": "CONFIRMED",
        "contact": {
          "name": "Emma Baumann",
          "email": "emma.baumann@participant.ch",
          "phone": "+41791234567"
        },
        "origin": {
          "lat": 47.394585411360396,
          "lng": 8.53624404228385
        }
      },
      {
        "id": "3",
        "state": "CONFIRMED",
        "contact": {
          "name": "Mila Schneider",
          "email": "mila.schneider@participant.ch",
          "phone": null
        },
        "origin": null
      },
      {
        "id": "4",
        "state": "CONFIRMED",
        "contact": {
          "name": "Sofia Fischer",
          "email": "sofia.fischer@participant.ch",
          "phone": "+41791234567"
        },
        "origin": {
          "lat": 47.398279144654595,
          "lng": 8.523047751622567
        }
      },
      {
        "id": "5",
        "state": "CONFIRMED",
        "contact": {
          "name": "Jana Frei",
          "email": "jana.frei@participant.ch",
          "phone": "+41791234567"
        },
        "origin": {
          "lat": 47.398279144654595,
          "lng": 8.523047751622567
        }
      },
      {
        "id": "6",
        "state": "CONFIRMED",
        "contact": {
          "name": "Lara Graf",
          "email": "lara.graf@participant.ch",
          "phone": "+41791234567"
        },
        "origin": {
          "lat": 47.398279144654595,
          "lng": 8.523047751622567
        }
      }
    ],
    "attendants": [
      {
        "id": "100",
        "state": "ACKNOWLEDGED",
        "contact": {
          "name": "Papi von Sofia",
          "email": "sofia.papi@bar.foo",
          "phone": "+41791234567"
        },
        "type": "CAR",
        "capacity": 3,
        "stopovers": [
          {
            "id": "10101",
            "description": "Parkplatz bei Kiosk",
            "location": {
              "lat": 47.43135205825963,
              "lng": 8.563472465691515
            },
            "dateTime": "2023-03-15T13:00:00+0100",
            "joiners": [
              {
                "participantId": "1"
              }
            ]
          },
          {
            "id": "10102",
            "description": "Bushaltestelle Laubiweg",
            "location": {
              "lat": 47.48035200025963,
              "lng": 8.534472005691515
            },
            "dateTime": "2023-03-15T13:15:00+0100",
            "joiners": []
          }
        ]
      },
      {
        "id": "101",
        "state": "ACKNOWLEDGED",
        "contact": {
          "name": "Eveline (Mami von Emma)",
          "email": "evelin@irgendwas.ch",
          "phone": "+41791234567"
        },
        "type": "PT",
        "capacity": 10,
        "stopovers": [
          {
            "id": "258",
            "description": "Zürich HB, Gleiskopf, Gleis 9",
            "location": {
              "lat": 47.43135205825963,
              "lng": 8.563472465691515
            },
            "time": "2023-03-15T11:15:00+0100",
            "joiners": [
              {
                "participantId": "3"
              }
            ]
          },
          {
            "id": "741",
            "description": "Bahnhof Sellenbüren, beim Kiosk",
            "time": "2023-03-15T10:20:00+0100",
            "joiners": []
          }
        ]
      }
    ]
  }
]
