<h1>Edit Attendant</h1>

<div>
  <h4><a [routerLink]="['../..']"><i class="bi bi-arrow-left"></i></a></h4>
</div>

<form [formGroup]="form" (ngSubmit)="submit()">

  <div class="pt-0">
    <text formControlName="name" [config]="nameInputConfig"></text>
  </div>

  <div class="pt-3">
    <email formControlName="email" [config]="emailInputConfig"></email>
  </div>

  <div class="pt-3">
    <phone formControlName="phone" [config]="phoneInputConfig"></phone>
  </div>

  <ng-template *ngIf="isCarSelected()">
    <div class="pt-3">
      <number-input formControlName="capacity"
                    [config]="capacityInputConfig">
      </number-input>
    </div>
  </ng-template>

  <div class="pt-4">
    <div class="row">
      <div class="col text-end">
        <button type="button"
                class="btn btn-outline-secondary w-50"
                (click)="back()">Back</button>
      </div>
      <div class="col text-start">
        <button type="submit"
                class="btn btn-primary w-50"
                [disabled]="form.invalid"
                [ngClass]="{'invalid': form.invalid}">Save</button>
      </div>
    </div>
  </div>

</form>

<!--
<form-debug [formGroup]="form"></form-debug>
-->
