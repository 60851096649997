import {
  Component,
  Input,
  OnInit
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";

@Component({
  template: ''
})
export abstract class FormComponentBase<T> implements ControlValueAccessor, Validator, OnInit {

  _config?: any
  __config(value: any) {
    this._config = value;
  }

  // Attribute REQUIRED
  private _required?: boolean
  @Input()
  set required(value: boolean) {
    this._required = value
  }
  get required():boolean {
    // explicit attribute over configuration over default
    return this._required ?? (this._config?.required ?? true)
  }

  private _requiredFeedback?: string
  private _defaultRequiredFeedback = "Input required"
  @Input()
  set requiredFeedback(value: string) {
    this._requiredFeedback = value
  }
  get requiredFeedback(): string {
    return this._requiredFeedback ?? (this._config?.requiredFeedback ?? this._defaultRequiredFeedback)
  }


  // Form
  formControl: FormControl<T|null> = new FormControl<T|null>(null)

  ngOnInit(): void {
    if(this.required) {
      this.formControl.addValidators(Validators.required)
    }
    this.formControl.valueChanges.subscribe((value:T|null) => {
      // !! the onChange Method can be passed directly, BUT there is no context,
      // variable "this" is always undefined !!
      this.onChange(value)
    });
  }

  onChange(value: T | null) {
  }

  // ControlValueAccessor ------------------------------------------------------

  _onChange = (value: T | null) => {};

  registerOnChange(fn: (value: T | null) => void): void {
    this.formControl.valueChanges.subscribe(fn);
    this._onChange = fn;
  }

  _onTouched = () => {};

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable()
    } else {
      this.formControl.enable()
    }
  }

  writeValue(value: T | null): void {
    this.formControl.setValue(value, { emitEvent: false })
  }

  // Validator ---------------------------------------------------------------

  onValidationChange: any = () => {};

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formControl.errors;
  }
}


export interface GenericInputConfiguration {

  required?: boolean

  requiredFeedback?: string

}
